import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import ScrollTopButton from "./components/ScrollTopButton";
import PrivateRoute from "./components/PrivateRoute";
import { AuthContext } from "../context/auth";
import fetchKubunAction from "../store/fetchKubun";
import fetchElectricAction from "../store/fetchElectric";
import { isEmpty } from "lodash";

const AdminLayout = Loadable({
    loader: () => import("./layout/AdminLayout"),
    loading: Loader,
});

class App extends Component {
    constructor(props) {
        super(props);
        const authToken = localStorage.getItem('authToken');
        this.state = {
            authToken
        }
    };

    setAuthToken = token => {
        if (token) {
            localStorage.setItem('authToken', token);
        } else {
            localStorage.removeItem('authToken');
        }

        this.setState({ authToken: token });
    };

    componentDidMount = () => {
        const { 
            kubun,
            fetchKubun,
            electric,
            fetchElectric
        } = this.props;
    
        if (isEmpty(kubun)) fetchKubun();
        if (isEmpty(electric)) fetchElectric();
    }

    render() {
        const menu = routes.map((route, index) => {
            return route.component ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                />
            ) : null;
        });

        return (
            <AuthContext.Provider value={{ authToken: this.state.authToken, setAuthToken: this.setAuthToken }}>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                    <Switch>
                        {menu}
                        <Route exact path="/">
                            <Redirect to="/customer" />
                        </Route>
                        <PrivateRoute path="/" component={AdminLayout} />
                    </Switch>
                    </Suspense>
                </ScrollToTop>
                <ScrollTopButton scrollStepInPx="50" delayInMs="16.66" />
            </AuthContext.Provider>
        );
    }
}

const mapStateToProps = (state) => ({
    kubun: state.kubun,
    electric: state.electric,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchKubun: fetchKubunAction,
    fetchElectric: fetchElectricAction,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
