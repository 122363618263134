import React from "react";
import moment from "moment";
import queryString from "query-string";
import { isObject, isEmpty } from "lodash";

export const displayError = (error) => {
    if (!isEmpty(error)) {
        if (Array.isArray(error)) {
            return error.join("\n");
        }
        if (isObject(error)) {
            let msg = [];
            for (let prop in error) {
                msg.push(error[prop].join("\n"));
            }
            return msg.join("\n");
        }

        return error;
    }

    return "";
};

export const generateOptions = (options) => {
    return options
        ? options.map((item, index) => {
            return (
                <option key={index} value={item.value}>
                    {item.display_name}
                </option>
            );
        })
        : null;
};

export const getOptionsByValue = (options, value) => {
    let data = options.filter(function (n){
        return n.value === value;
    });

    if (isEmpty(data) || isEmpty(data[0]) )
        return "";

    return data[0].display_name;
};

export const objectToFormData = (object) => {
    let formData = new FormData();
    for (let key in object) {
        if (object[key] || object[key] === 0) {
            formData.append(key, object[key]);
        } else {
            formData.append(key, "");
        }
    }

    return formData;
};

export const convertDate = (date, format) => {
    if (!date) return null;
    if (!format) {
        format = "YYYY-MM-DD";
    }

    return moment(date).format(format) !== "Invalid date"
        ? moment(date).format(format)
        : null;
};

export const parseQueryParamsForSearch = (query, acceptedParams) => {
    const queryObject = queryString.parse(query);
    const searchParamsObject = {};

    acceptedParams.forEach((param) => {
        searchParamsObject[param] = queryObject.param || "";
    });

    return queryObject;
};

export const downloadFile = (response, filename) => {
    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type: type, endings: "native" });
    const URL = window.URL || window.webkitURL;
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.removeChild(a);
};

export const convertSearchParams = (values) => {
    Object.keys(values).forEach(function (key) {
        if (typeof (values[key]) === "string") {
            var value = "";
            var str = values[key];
            let y = "";
            let boolean = false;
            for (var i = 0; i < str.length; i++) {
                var c = str.charCodeAt(i);
                if (c === 12288) {
                    if (boolean === true) {
                        y += String.fromCharCode(c);
                        continue;
                    }
                } else {
                    boolean = true;
                    y += String.fromCharCode(c);
                    value = y;
                }
            }
            values[key] = value;
        }
    });
};

export const hankaku2Zenkaku = (string) => {
    return string.replace(/[A-Za-z0-9]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
    });
};
