import { fetchKubunSuccess } from "./actions";
import apiCaller from "../utils/api";

function fetchKubun() {
    return dispatch => apiCaller.getRequest('kubunMaster/list', {},
        (response) => {
            dispatch(fetchKubunSuccess(response.results.data));
        },
       (error) => {
            dispatch(fetchKubunSuccess({}));
            return {};
        }
    );
}

export default fetchKubun;
